<template>
  <div id="profile">
    <van-row>
      <van-col span="20" offset="2">
        <van-field
          v-model="name"
          type="text"
          placeholder="Name"
          size="large"
          border
        />
      </van-col>
    </van-row>
    <van-row>
      <van-col span="20" offset="2">
        <van-field
          v-model="magiqWord"
          type="text"
          placeholder="Magiq Word"
          size="large"
          border
        />
      </van-col>
    </van-row>
    <template v-if="isEmail">
      Change Email
      <van-row>
        <van-col span="20" offset="2">
          <van-field
            v-model="oldEmail"
            type="email"
            placeholder="Enter Current Email"
            maxlength="60"
            size="large"
            :error-message="oldEmailFieldError"
            border
          />
        </van-col>
      </van-row>
      <van-row>
        <van-col span="20" offset="2">
          <van-field
            v-model="newEmail"
            type="email"
            placeholder="Enter New Email"
            maxlength="60"
            size="large"
            :error-message="newEmailFieldError"
            border
          />
        </van-col>
      </van-row>
    </template>
    <template v-else>
      <van-row>
        <van-col span="20" offset="2">
          <van-field
            name="loginMethod"
            label="Enable Email Login"
            style="text-align: left;"
          >
            <template #input>
              <van-switch v-model="loginMethod" size="20" />
            </template>
          </van-field>
        </van-col>
      </van-row>
      <van-row v-if="loginMethod">
        <van-col span="20" offset="2">
          <van-field
            v-model="email"
            type="email"
            placeholder="Enter your Email"
            maxlength="60"
            size="large"
            :error-message="emailFieldError"
            border
          />
        </van-col>
      </van-row>
    </template>
    <AudioPreference
      v-for="preference in profileInfo.user_preferences"
      :userPreference="preference"
      :key="preference.id"
      @changeAudioValue="changeAudioValue($event)"
    />

    <van-row>
      <van-col span="20" offset="2">
        <van-button
          type="primary"
          round
          text="Submit"
          size="large"
          @click.prevent="handleUpdateProfile"
        />
      </van-col>
    </van-row>
  </div>
</template>

<script>
import { mapState, mapActions } from "vuex";
import { Dialog } from "vant";
import { CurrentUser, Login } from "@/helpers";
import AudioPreference from "@/components/Profile/AudioPreference";
import { router } from "@/router";

export default {
  name: "Profile",
  components: { AudioPreference },
  props: {
    profileInfo: [Array, Object]
  },
  data() {
    return {
      name: this.profileInfo.name,
      magiqWord: this.profileInfo.magiq_word,
      isEmail: this.profileInfo.email !== "",
      audio: false,
      loginMethod: false,

      oldEmailFieldError: null,
      newEmailFieldError: null,
      emailFieldError: null
    };
  },

  computed: {
    ...mapState({
      updating: state => state.profile.status.updating,
      updated: state => state.profile.status.updated,
      updateFailed: state => state.profile.status.updateFailed
    }),

    ...mapState({
      preferenceUpdating: state => state.preference.status.updating,
      preferenceUpdated: state => state.preference.status.updated,
      preferenceUpdateFailed: state => state.preference.status.updateFailed
    })
  },

  watch: {
    updated(updated) {
      if (!updated) {
        return;
      }

      Dialog.alert({
        title: "Profile updated successfully",
        confirmButtonText: "Okay"
      }).then(() => {
        let userObject = CurrentUser.get();
        userObject.displayName = this.magiqWord + " " + this.name;
        CurrentUser.set(userObject);

        router.go();
      });
    },

    updateFailed(updateFailed) {
      if (!updateFailed) {
        return;
      }

      Dialog.alert({
        title: "Profile update failed",
        message:
          "Something went wrong during updating profile. Please contact your mentors.",
        confirmButtonText: "Okay"
      });

      this.preferenceUpdating = false;
    },

    preferenceUpdated(preferenceUpdated) {
      if (!preferenceUpdated) {
        return;
      }
    },
    preferenceFailed(preferenceFailed) {
      if (!preferenceFailed) {
        return;
      }

      Dialog.alert({
        title: "Preference update failed",
        message:
          "Something went wrong during updating user preference. Please contact your mentors.",
        confirmButtonText: "Okay"
      });

      this.preferenceUpdating = false;
    }
  },

  methods: {
    ...mapActions("profile", ["getProfileInfo", "updateProfile"]),
    ...mapActions("preference", ["updatePreference"]),
    ...mapActions("account", ["getUserInfo"]),

    async handleUpdateProfile() {
      if (this.loginMethod) {
        if (!this.email || this.email.length == 0) {
          this.emailFieldError = "Email required";
          return;
        } else if (!Login.isValidEmail(this.email)) {
          this.emailFieldError = "Invalid email";
          return;
        }
      } else if (
        (this.oldEmail && this.oldEmail.length > 0) ||
        (this.newEmail && this.newEmail.length > 0)
      ) {
        if (!this.oldEmail || this.oldEmail.length == 0) {
          this.oldEmailFieldError = "Current email required";
        } else if (!Login.isValidEmail(this.oldEmail)) {
          this.oldEmailFieldError = "Invalid email";
        }
        if (!this.newEmail || this.newEmail.length == 0) {
          this.newEmailFieldError = "New email required";
        } else if (!Login.isValidEmail(this.newEmail)) {
          this.newEmailFieldError = "Invalid email";
        }
        if (
          this.oldEmailFieldError !== null ||
          this.newEmailFieldError !== null
        ) {
          return;
        }
      }
      await this.updateProfile({
        params: {
          name: this.name,
          magiq_word: this.magiqWord,
          oldEmail: this.oldEmail,
          newEmail: this.newEmail,
          loginMethod: this.loginMethod,
          email: this.email
        }
      });

      this.handleUpdatePreference();
      this.updateUserInfo();
    },

    async handleUpdatePreference() {
      await this.updatePreference({
        params: [{ key: "audio", value: this.audio }]
      });
    },

    changeAudioValue(value) {
      this.audio = value;
    },

    async updateUserInfo() {
      let userObject = CurrentUser.get();
      userObject.displayName = this.magiqWord + " " + this.name;
      CurrentUser.set(userObject);

      await this.getUserInfo({
        user: userObject
      });
    }
  }
};
</script>

<style scoped>
.van-row {
  margin-bottom: 20px;
  margin-top: 10px;
}

.token-login .van-row {
  margin-bottom: 20px;
}

.show-token {
  cursor: pointer;
}

.token-login .token {
  border-radius: 10em;
  height: 50px;
  line-height: 48px;
  padding-left: 10px;
  width: 97%;
}

.van-switch {
  margin-left: 140px !important;
}
</style>
