<template>
  <div id="user_preference">
    <van-row v-if="userPreference.key === 'audio'">
      <van-col span="20" offset="2">
        <van-field name="audio" label="Mute Audio" style="text-align: left;">
          <template #input>
            <van-switch
              v-model="audio"
              size="20"
              @change="changeAudioValue(audio)"
            />
          </template>
        </van-field>
      </van-col>
    </van-row>
  </div>
</template>

<script>
export default {
  name: "AudioPreference",
  props: {
    userPreference: [Object]
  },

  data() {
    return {
      audio: false
    };
  },

  mounted() {
    if (this.userPreference.key == "audio" && this.userPreference.value == 1) {
      this.audio = true;
    } else {
      this.audio = false;
    }
    this.$emit("changeAudioValue", this.audio);
  },

  methods: {
    changeAudioValue(value) {
      this.$emit("changeAudioValue", value);
    }
  }
};
</script>

<style scoped>
.van-switch {
  margin-left: 140px !important;
}
</style>
