<template>
  <Profile :profileInfo="profileInfo" :key="profileInfo.id" />
</template>

<script>
import { mapState, mapActions } from "vuex";
import Profile from "@/components/Profile";

export default {
  name: "ProfilePage",
  components: {
    Profile
  },
  computed: {
    ...mapState({
      profileInfo: state => state.profile.profileInfo
    })
  },

  mounted() {
    this.getProfileInfo();
  },
  methods: {
    ...mapActions("profile", ["getProfileInfo"])
  }
};
</script>

<style scoped></style>
